<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Role -->
        <b-col cols="12" md="4">
          <b-form-group
              label="Employee"
              label-for="user-role"
          >
            <select v-model.number="employee_id" class="form-control"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
              <option :value="null">Select One</option>
              <option v-for="(employee, key) in employees" :value="employee.id" :key="key">{{ employee.institute_emp_id? employee.institute_emp_id : 'TBD' }} - {{ employee.userable.name }} </option>
          
            </select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-form v-if="employee_id" ref="update_form" action="javascript:void(0)" @submit="updatePermission()">
        <!-- PERMISSION TABLE -->
        <b-card
            class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                  icon="LockIcon"
                  size="18"
              />
              <span class="align-middle ml-50">Permission</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="3" class="mt-2" v-for="(permission,key) in permissions" :key="key">
                <b-card-title>{{permission.name}}</b-card-title>
                  <b-form-checkbox v-for="(pm,key2) in permission.permissions" name="permissions[]" :value="pm.pivot.id" v-model="current_permissions" :checked="true" :key="key2">{{pm.alias.toLowerCase()}}</b-form-checkbox>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <!-- Action Buttons -->
        <b-button v-if="$can('update','Permission')"
            variant="primary" type="submit"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Save Changes
        </b-button>
    </b-form>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BForm, BCard, BCardHeader, BCardTitle, BFormCheckbox,
    BCardBody,
} from 'bootstrap-vue'
//import {mapGetters} from "vuex";
import apiCall from "@/libs/axios";
export default {
  components: {
    BButton,
    BRow,BCardBody,
    BCol,
    BFormGroup,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  data(){
    return{
      user_id:null,
      chk:[],
      current_permissions:[],
      employee_id:null,
      is_loading:false,
    }
  },
  created() {
    if(this.permissions.length<1 ) this.$store.dispatch('GET_ALL_MENU_PERMISSION');
    if(this.employees.length <1 ) this.$store.dispatch('GET_ALL_EMPLOYEE');
  },

  methods: {
    async updatePermission(){
      let data =new FormData(this.$refs.update_form);
        this.is_loading=true;
        await apiCall.post(`/menu/wise/user/permission/update${this.user_id}`,data).then((response)=>{
            this.user_id = null;
            this.employee_id = null;
            this.$toaster.success(response.data.message);
            this.$store.dispatch('GET_ALL_EMPLOYEE');
            this.is_loading=false;
        }).catch((error)=>{
          this.is_loading=false;
          this.$toaster.success(error.data.message);
        })
    },
    findCurrentPermission(){
      let pm = this.employees.find(item=>parseInt(item.id)===parseInt(this.employee_id));
      if(pm){
        this.current_permissions=[];
        this.user_id=pm.userable.id;
        this.current_permissions.push(...pm.userable.menu_permissions.map(item=>item.id));
      }
      else{
        this.current_permissions=[];
      }
    },
  },
  computed: {
    permissions(){
      return this.$store.getters.permissions.filter(item=>item.type == 1);
    },
    employees(){
      let employeeList = [];
      for (let i = 0; i < this.$store.getters.employees.length; i++) {
          if (this.$store.getters.employees[i].userable != null) {
              employeeList.push(this.$store.getters.employees[i]);
          }
      }
      return employeeList;
    }
  },
  watch:{
    employee_id(){
      this.findCurrentPermission();
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
